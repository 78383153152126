const API_URLS = {
  SYMPTOMTYPES: `/api/template/symptomtype`,
  SYMPTOMTYPE: (id) => `/api/template/symptomtype/${id}`,
  PATCHSMPTOMTYPE: (id) => `/api/symptomtype/${id}/edit`,
  COPYSYMPTOMTYPE: (id) => `/api/symptomtype/${id}/copy`,
  //questions
  QUESTIONS: `/api/question`,
  QUESTIONSWITHCHILDREN: `/api/question?isFlattened=true&excludeChildrenOnRootLevel=false`,
  QUESTIONWITHFAMILY: (id) => `/api/question/${id}/family`,
  SYMPTOMTYPEQUESTIONS: (id) => `/api/template/symptomform/${id}/question`,
  PATCHQUESTION: (id) => `/api/question/${id}/edit`,
  PATCHQUESTIONFAMILY: (id) => `/api/question/${id}`,
  POSTQUESTION: `/api/question`,
  REMOVEPARENTQUESTIONID: (id) => `/api/question/${id}/parent`,
  //items
  SYMPTOMTYPEITEMS: (id) => `/api/template/symptomform/${id}/item`,
  ITEMSWITHCHILDREN: `/api/item?isFlattened=true`,
  ITEMWITHFAMILY: (id) => `/api/item/${id}/family`,
  ITEMS: `/api/item`,
  REMOVEPARENTITEMID: (id) => `/api/item/${id}/parent`,
  PATCHITEMFAMILY: (id) => `/api/item/${id}`,
  PATCHITEM: (id, editGroupTitleForGroup) =>
    `/api/item/${id}/edit?editGroupTitleForGroup=${editGroupTitleForGroup}`,
  POSTITEM: `/api/item`,
};

const VIEWS = {
  START: "/",
  QUESTIONS: "/questions",
  QUESTION: "/questions/:questionId",
  NEWQUESTION: "/questions/newQuestion",
  SYMPTOMTYPES: "/symptomtypes",
  SYMPTOMTYPE: "/symptomtypes/:symptomTypeId",
  ITEMS: "/items",
  ITEM: "/items/:itemId",
  NEWITEM: "/items/newItem",
};

export { API_URLS, VIEWS };
