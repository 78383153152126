const inputTemplates = {
  0: "String",
  1: "MediumString",
  2: "LongString",
  3: "Boolean",
  4: "YesNoBoolean",
  5: "Double",
  6: "Checkbox",
  7: "Radio",
  8: "PastDate",
  9: "FutureDate",
};

const itemTypes = {
  1: "StatusItem",
  2: "TreatmentItem",
  3: "DiagnoseItem",
  4: "SubDiagnoseItem",
};

const inputTemplateIdEnum = {
  1: "1-7 days",
  167: "Checkbox (hide children on action)",
  247: "degrees (long string)",
  343: "pos/neg (boolean)",
  344: "mg/l (string)",
  345: "g/l (string)",
  346: "Celsius no limits (string)",
  347: "mmol/l (string)",
  348: "-10 to 10",
  453: "fullt vaken/annan (boolean manage on false)",
  455: "normal/annan (boolean manage on false)",
  457: "regelbunden/oregelbunden (boolean)",
  458: "slag/min (string)",
  459: "mmHg (string)",
  461: "andetag/min (string)",
  462: "normal/ytlig (boolean)",
  468: "0-300 kg",
  509: "degrees (string)",
  1001: "String",
  1002: "Long string",
  1003: "Boolean",
  1004: "YesNoBoolean (manage children on YES)",
  1005: "35-42 Celsius",
  1006: "1-14 days",
  1007: "1-10 times/day",
  1008: "1-43 weeks",
  1009: "Checkbox",
  1010: "Radio button",
  1011: "0-100 %",
  1012: "Past date",
  1013: "YesNoBoolean (manage children on NO)",
  1014: "Future date",
  1021: "Utfört/ej utfört (boolean)",
};

const targetGroups = {
  0: "General",
  1: "FertileFemale",
  2: "Smoker",
  3: "Period",
  4: "OlderFemale",
  5: "STDgroup",
  6: "Employed",
  7: "Unemployed",
  8: "ParentalLeave",
  9: "Student",
  10: "Female",
};

const symptomTypeSort = {
  0: "Standard",
  1: "Emergency",
  2: "Consulting",
  3: "SickLeave",
  4: "Vaccination",
};

export {
  inputTemplates,
  targetGroups,
  symptomTypeSort,
  inputTemplateIdEnum,
  itemTypes,
};
